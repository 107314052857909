.col-md-3 {
    height: 155px;
    padding: 10px 0;
    margin: 1px;
    width: 24%;
}

.active {
    background: #fcbf3f;
    border-radius: 15px;
}

.novo {
    font-size: 30px;
    font-weight: bold;
}

.h1-header {
    color: #00692c;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 8px solid #ccc;
    border-top-color: #00692c;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }


  .active {
    background-color: #fcbf3f;
  }