.rodape-trans {
	.Cookie--dark-lime {
		.Cookie__button {
			background: #00692c !important;
		}
	}
}

.footer {
    border-top: 3px solid #eaeaea;
    background-color: #f5f5f5;
    font-family: 'Open Sans', sans-serif;
}

.footer ul,
.footer li {
    padding: 0;
    margin: 0;
}

.footer-col {
    margin-top: 15px;
}

.footer-col p {
    color: #999999;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 15px;
}

.logofooter {
    margin-bottom: 10px;
    font-size: 25px;
    color: #999999;
    font-weight: 700;
}

.footer-ul {
    list-style-type: none;
    padding-left: 0;
    margin-left: 2px;
}

.footer-ul li {
    line-height: 29px;
    font-size: 12px;
}

.footer-ul li a {
    color: #999999;
    transition: color 0.2s linear 0s, background 0.2s linear 0s;
}

.footer-ul li a:hover {
    color: #999999;
}

.footer-ul i {
    margin-right: 10px;
}

.bottom_ul {
    list-style-type: none;
    float: right;
    margin-bottom: 0;
}

.bottom_ul li {
    float: left;
    line-height: 40px;
}

.bottom_ul li a {
    color: #999999;
    font-size: 12px;
}

.bottom_ul li:after {
    content: "/";
    color: #999999;
    margin-right: 8px;
    margin-left: 8px;
}

.social-icon {
    width: 30px;
    height: 30px;
    font-size: 15px;
    background-color: blue;
    color: #ffffff;
    text-align: center;
    margin-right: 10px;
    padding-top: 7px;
    border-radius: 50%;
}

.footer-social li {
    float: left;
}

@media (max-width: 768px) {
    .footer-social li {
        float: none;
    }
}

/* Media Queries para ajustar o layout em diferentes tamanhos de tela */

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
