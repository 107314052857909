.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .not-found-animation {
    margin-top: 20px;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    border: 8px solid #ccc;
    border-top-color: #00692c; /* Altere a cor conforme necessário */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  