@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@media (min-width: 1536px) {
    .container {
        max-width: 1280px;
    }
}