.footer {
	border-top: 3px solid #eaeaea;
	background-color: #f5f5f5;
	font-family: 'Open Sans', sans-serif;
	li {
		list-style-type: none;
	}
}
.footer ul,
.footer li {
	padding: 0;
	margin: 0;
}
.footer-col {
	margin-top: 15px;
	p {
		color: #999999;
		font-size: 12px;
		font-family: 'Open Sans', sans-serif;
		margin-bottom: 15px;
		i {
			width: 20px;
			color: #999999;
		}
	}
}
.logofooter {
	margin-bottom: 10px;
	font-size: 25px;
	color: #999999;
	font-weight: 700;
}
.footer-ul {
	list-style-type: none;
	padding-left: 0;
	margin-left: 2px;
	li {
		line-height: 29px;
		font-size: 12px;
		a {
			color: #999999;
			transition: color 0.2s linear 0s, background 0.2s linear 0s;
			&:hover {
				transition: color 0.2s linear 0s, background 0.2s linear 0s;
				color: #999999;
			}
		}
	}
	i {
		margin-right: 10px;
	}
}
.copyright {
	min-height: 40px;
	p {
		text-align: left;
		color: #999999;
		padding: 10px 0;
		margin-bottom: 0;
	}
}
.heading7 {
	font-size: 21px;
	font-weight: 700;
	color: #d9d6d6;
	margin-bottom: 22px;
}
.post {
	p {
		font-size: 12px;
		color: #ffffff;
		line-height: 20px;
		span {
			display: block;
			color: #8f8f8f;
		}
	}
}
.bottom_ul {
	list-style-type: none;
	float: right;
	margin-bottom: 0;
	li {
		float: left;
		line-height: 40px;
		a {
			color: #999999;
			font-size: 12px;
		}
		&:after {
			content: "/";
			color: #999999;
			margin-right: 8px;
			margin-left: 8px;
		}
	}
}
.social-icon {
	width: 30px;
	height: 30px;
	font-size: 15px;
	background-color: blue;
	color: #ffffff;
	text-align: center;
	margin-right: 10px;
	padding-top: 7px;
	border-radius: 50%;
}
.footer-social {
	li {
		float: left;
	}
}
.linked-in {
	background-color: #007bb6;
}
.facebook {
	background-color: #3b5998;
}
.twitter {
	background-color: #1da1f2;
}
.google {
	background-color: #f63e28;
}
.instagram {
	background-color: #3f729b;
}
.shape {
	border-style: solid;
	border-width: 0 40px 40px 0;
	float: right;
	height: 0px;
	width: 0px;
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
	transform: rotate(360deg);
}
.shape-text {
	color: #ffffff;
	font-size: 12px;
	font-weight: bold;
	position: relative;
	right: -22px;
	top: -3px;
	white-space: nowrap;
	-ms-transform: rotate(30deg);
	-o-transform: rotate(360deg);
	-webkit-transform: rotate(46deg);
	transform: rotate(46deg);
}
.offer {
	background: #ffffff;
	border: 1px solid #dddddd;
	margin-bottom: 10px;
	overflow: hidden;
	padding: 0 0 5px 5px;
}
.offer-content {
	.lead {
		margin-bottom: 5px;
		padding-left: 10px;
		padding-bottom: 10px;
	}
}
.offer-radius {
	border-radius: 7px;
}
.offer-danger {
	border-color: #d9534f;
	.shape {
		border-color: transparent #d9534f transparent transparent;
	}
}
.offer-success {
	border-color: #5cb85c;
	.shape {
		border-color: transparent #5cb85c transparent transparent;
	}
}
.offer-default {
	border-color: #999999;
	.shape {
		border-color: transparent #999999 transparent transparent;
	}
}
.offer-primary {
	border-color: #428bca;
	.shape {
		border-color: transparent #428bca transparent transparent;
	}
}
.offer-info {
	border-color: #5bc0de;
	.shape {
		border-color: transparent #5bc0de transparent transparent;
	}
}
.offer-warning {
	border-color: #f0ad4e;
	.shape {
		border-color: transparent #f0ad4e transparent transparent;
	}
}
.list-group-horizontal {
	.list-group-item {
		margin-top: 15px;
		display: inline-block;
		margin-bottom: 0;
		margin-left: -4px;
		margin-right: 0;
		border-radius: 0;
	}
}
#rodape {
	.dev {
		color: #999999;
	}
}
.h1-header {
	color: #00692c;
}
.v4-tease {
	display: block;
	padding: 1% 1em;
	font-weight: 700;
	color: #fff;
	text-align: center;
	background-color: #fcbf40;
}
.no-padding {
	padding: 0;
}
.no-margin {
	margin: 0;
}
.vertical-center {
	min-height: 100%;
	display: flex;
	align-items: center;
}
.logos {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #f5f5f5;
}
.container-3 {
	width: 100%;
	vertical-align: middle;
	white-space: nowrap;
	position: relative;
	input#search {
		width: 100%;
		height: 50px;
		background: #fff;
		float: left;
		color: #262626;
		padding-left: 45px;
		border: 2px solid #fcbf40;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		color: #828282;
	}
	.icon {
		position: absolute;
		top: 50%;
		margin-left: 17px;
		margin-top: 15px;
		z-index: 1;
		color: #828282;
		transition: all .55s ease;
		left: 0;
	}
}
.shadow {
	-webkit-box-shadow: 0 10px 4px -4px #d5d5d5;
	-moz-box-shadow: 0 10px 4px -4px #d5d5d5;
	box-shadow: 0 10px 4px -4px #d5d5d5;
}
.fa-x {
	font-size: 1.5em;
}
#myBtn {
	display: none;
	position: fixed;
	bottom: 3em;
	right: .5em;
	z-index: 99;
	border: none;
	cursor: pointer;
	color: #00692c;
}
a {
	&.service {
		color: #00692c;
	}
}
.service-box {
	display: inline-block;
	.service-icon {
		width: 75px;
		height: 75px;
		border-radius: 50px;
		border: 3px solid #e9e9e9;
		line-height: 75px;
		font-size: 50px;
		transition: all 0.5s ease 0s;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			height: 45px;
		}
	}
	.service-text {
		margin-bottom: 20px;
	}
}
.side-menu-wrapper {
	overflow: hidden;
	background: rgba(0,0,0,0.95);
	padding: 40px 0 0 40px;
	position: fixed;
	top: 0;
	right: -290px;
	height: 100%;
	z-index: 2;
	transition: 0.5s;
	width: 250px;
	font: 20px;
	& > ul {
		list-style: none;
		padding: 0;
		margin: 0;
		overflow-y: auto;
		width: 500px;
		height: 95%;
		& > li {
			& > a {
				display: block;
				border-bottom: 1px solid #131313;
				padding: 6px 4px 6px 4px;
				color: #989898;
				transition: 0.3s;
				text-decoration: none;
			}
		}
	}
	& > a {
		&.menu-close {
			padding: 8px 0 4px 23px;
			color: #6B6B6B;
			display: block;
			margin: -30px 0 -10px -20px;
			font-size: 35px;
			text-decoration: none;
		}
	}
}
.side-menu-overlay {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.7);
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
	opacity: 0;
	transition: opacity 1s;
}
th {
	&.sorting {
		&.left-align {
			background: #f3f4f4 !important;
			color: #888989 !important;
		}
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1170px;
	}

}


@media (min-width: 992px) {
	.container {
		width: 970px;
	}
}

@media (min-width: 768px) {
	.container {
		width: 750px;
	}
}
